const ENVIRONMENTS = {
  dev: {
    apiBase: 'http://localhost:8800/api',
  },
  prod: {
    apiBase: 'https://arhiv.srebrenicamemorial.org/api',
  },
};

const ENV = ENVIRONMENTS[process.env.REACT_APP_ENV];

export default ENV;
